import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a0be67a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.meetingDay)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["next-meeting", { 'vertical-display': _ctx.verticalDisplay }])
      }, [
        _createTextVNode(" Réunion " + _toDisplayString(_ctx.formatDay(_ctx.meetingDay)) + " ", 1),
        (_ctx.isVisio)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "en visio"))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "dans les locaux (tous au bureau)"))
      ], 2))
    : _createCommentVNode("", true)
}