export default {
  formatDay(dateString: string, hideMonth?: boolean) {
    const daysOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const months = [
      "jan",
      "fév",
      "mar",
      "avr",
      "mai",
      "juin",
      "juil",
      "aou",
      "sep",
      "oct",
      "nov",
      "déc",
    ];
    const date = new Date(dateString);
    const parts = [];
    parts.push(daysOfWeek[date.getUTCDay()]);
    parts.push(date.getDate());
    if (hideMonth ?? false) {
      parts.push(months[date.getMonth()]);
    }
    return parts.join(" ");
  },
  computeMeetingDay(days: Array<string>, weekNumber?: number): string {
    if (days.length > 4) {
      // Thursday on even weeks, Friday on odds
      if ((weekNumber ?? 0) % 2 == 0) {
        return days[3];
      } else {
        return days[4];
      }
    }
    return "";
  },
  isVisioMeeting(weekNumber?: number) {
    return (weekNumber ?? 0) % 2 == 0;
  },
};
