
import { defineComponent } from "vue";
import fetchGet from "@/fetchGet";
import fetchPut from "@/fetchPut";
import fetchDelete from "@/fetchDelete";
import MeetingView from "@/views/MeetingView.vue";
import DateUtils from "@/date-utils";

export default defineComponent({
  name: "WeekTable",
  props: {
    week: Number,
    year: Number,
  },
  components: { MeetingView },
  data() {
    return {
      users: [],
      days: [],
      presences: {},
      absences: {},
      meetingDay: "",
      isVisioMeeting: false,
      verticalDisplay: false,
      timer: 0,
      longpress: false,
    };
  },
  watch: {
    week(newWeek, oldWeek) {
      this.loadWeek();
    },
    days(newDays, oldDays) {
      this.meetingDay = DateUtils.computeMeetingDay(newDays, this.week);
      this.isVisioMeeting = DateUtils.isVisioMeeting(this.week);
    }
  },
  created() {
    fetchGet(`/users`).then((res) => {
      if (res.ok) {
        res.json().then((u) => {
          this.users = u;
          this.loadWeek();
        });
      }
    });

    this.verticalDisplay = window.innerWidth < window.innerHeight;
  },

  methods: {
    loadWeek() {
      const weekYear = {
        week: this.week,
        year: this.year,
      };
      fetchGet(`/days`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.days = u;
            this.loadPresences();
          });
        }
      });
    },
    loadPresences() {
      let weekYear = {
        week: this.week,
        year: this.year,
        isPresent: true,
      };
      fetchGet(`/presences-by-week`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.presences = u;
          });
        }
      });
      weekYear.isPresent = false;
      fetchGet(`/presences-by-week`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.absences = u;
          });
        }
      });
    },
    toggle(date: string, user: any) {
      if (!this.longpress) {
        let pres: { [index: string]: string[] } = this.presences;
        let usersThisDay: string[] = pres[date];
        let uri = `/presences/${user.trigramme}/${date}`;
        if (!usersThisDay || usersThisDay.indexOf(user.trigramme) == -1) {
          uri = uri + "/true";
        } else {
          uri = uri + "/false";
        }
        fetchPut(uri).then(this.loadPresences);
      } else {
        this.longpress = false;
      }
    },
    delete(date: string, user: any) {
      let uri = `/presences/${user.trigramme}/`;
      if (date == 'week') {
        uri = uri + `${this.year}/${this.week}`;
      } else {
        uri = uri + `${date}`;
      }
      fetchDelete(uri).then(
        this.loadPresences
      );
    },
    toggleWeek(user: any) {
      if (!this.longpress) {
        let noneMissing = true;
        let pres: { [index: string]: string[] } = this.presences;
        for (let i = 0; i < this.days.length; i++) {
          let date = this.days[i];
          let usersThisDay: string[] = pres[date];

          if (!usersThisDay || usersThisDay.indexOf(user.trigramme) == -1) {
            noneMissing = false;
          }
        }
        let uri = `/presences/${user.trigramme}/${this.year}/${this.week}`;
        if (noneMissing) {
          uri = uri + "/false";
        } else {
          uri = uri + "/true";
        }
        fetchPut(uri).then(this.loadPresences);
      } else {
        this.longpress = false;
      }
    },
    countUsers(date: string) {
      let pres: { [index: string]: string[] } = this.presences;
      const trigrammes = pres[date];
      let result = 0;
      if (trigrammes) {
        this.users.forEach((user: any) => {
          if (trigrammes.indexOf(user.trigramme) != -1) {
            result++;
          }
        });
      }
      return result;
    },
    listUsers(date: string) {
      let pres: { [index: string]: string[] } = this.presences;
      const trigrammes = pres[date];
      const list: string[] = [];
      if (trigrammes) {
        this.users.forEach((user: any) => {
          if (trigrammes.indexOf(user.trigramme) != -1) {
            list.push(user.firstName);
          }
        });
        return list.join(", ");
      }
      return "Personne !";
    },
    alertWithUsersList(date: string) {
      alert(this.listUsers(date));
    },
    startTimer(e : Event, date: string, user: any) {
      e.preventDefault();
      if (this.timer === 0) {
        this.timer = setTimeout(() => {
          this.longpress = true;
          this.delete(date, user);
        }, 500);
      }
    },
    cancelTimer(e : Event, date: string, user: any) {
      e.preventDefault();
      if (this.timer !== 0) {
        if (date == 'week') {
          this.toggleWeek(user);
        } else {
          this.toggle(date, user);
        }
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
    formatDay(dateString: string) {
      return DateUtils.formatDay(dateString);
    }
  },
});
