
import { defineComponent } from 'vue';
import DateUtils from "@/date-utils";

export default defineComponent({
    name: 'MeetingView',
    props: {
        meetingDay: String,
        isVisio: Boolean,
        verticalDisplay: Boolean
    },
    methods: {
        formatDay(dateString: string) {
            return DateUtils.formatDay(dateString);
        }
    }
});
